"use strict";

var _jquery = require("jquery");

var _jquery2 = _interopRequireDefault(_jquery);

var _aos = require("aos");

var _aos2 = _interopRequireDefault(_aos);

require("bootstrap");

require("slick-carousel");

require("magnific-popup");

var _gsap = require("gsap");

var _gsap2 = _interopRequireDefault(_gsap);

var _ScrollTrigger = require("gsap/ScrollTrigger");

require("./scss/frontend/style.scss");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.jQuery = _jquery2.default;
window.$ = _jquery2.default;
(0, _jquery2.default)(function () {
  /*
  function updateParallax() {
      $('.parallax-default').each(function () {
          if (($(window).scrollTop() + $(window).height() > $(this).offset().top) && ($(window).scrollTop() < $(this).offset().top + $(this).height())) {
              var scrollTop = $(window).scrollTop();
              var distance = 50;
              var offset = ($(window).scrollTop() - $(this).offset().top);
              var total = $(window).height() * 2;
              var ratio = offset / total;
              var translate = (distance * ratio * -1);
              $(this).css({transform: 'translateY(' + translate + '%)'});
          }
      });
  }
  */
  (0, _jquery2.default)('.arrow-down a').on('click', function (event) {
    event.preventDefault();
    (0, _jquery2.default)('body, html').animate({
      scrollTop: (0, _jquery2.default)(".section-mitos").position().top
    }, 500, 'swing');
  });
  (0, _jquery2.default)('header .navbar-collapse').on('show.bs.collapse', function () {
    (0, _jquery2.default)('body').addClass('menu-collapsed');
  });
  (0, _jquery2.default)('header .navbar-collapse').on('hidden.bs.collapse', function () {
    (0, _jquery2.default)('body').removeClass('menu-collapsed');
  });
  (0, _jquery2.default)('.slider-homepage:not(.slick-initialized)').slick({
    autoplay: true,
    autoplaySpeed: 8000,
    dots: true,
    arrows: false,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    //appendDots: $('.section-slider .dots-container'),
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
      }
    }]
  });
  (0, _jquery2.default)('.slider-opciones.slickable:not(.slick-initialized)').each(function (index, element) {
    (0, _jquery2.default)(element).slick({
      autoplay: false,
      dots: true,
      arrows: true,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true
        }
      }]
    }).on('setPosition', function (event, slick) {
      slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });
  });
  (0, _jquery2.default)('.slider-quiebra:not(.slick-initialized)').each(function (index, element) {
    (0, _jquery2.default)(element).slick({
      autoplay: false,
      dots: true,
      arrows: true,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: "<button class='slick-prev slick-arrow' aria-label='Previous' type='button' style=''><div class='arrow'></div>Previous</button>",
      nextArrow: "<button class='slick-next slick-arrow' aria-label='Next' type='button' style=''><div class='arrow'></div>Next</button>",
      responsive: [{
        breakpoint: 767,
        settings: "unslick"
      }]
    });
  });
  (0, _jquery2.default)('.slider-aportar:not(.slick-initialized)').each(function (index, element) {
    (0, _jquery2.default)(element).slick({
      autoplay: false,
      dots: true,
      arrows: true,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: "<button class='slick-prev slick-arrow' aria-label='Previous' type='button' style=''><div class='arrow'></div>Previous</button>",
      nextArrow: "<button class='slick-next slick-arrow' aria-label='Next' type='button' style=''><div class='arrow'></div>Next</button>"
    });
  });
  /*
  $('.slider-diferencia:not(.slick-initialized)').each(function (index, element) {
      $(element).slick({
          autoplay: false,
          dots: true,
          arrows: false,
          infinite: false,
          mobileFirst: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          initialSlide: 1,
          responsive: [
              {
                  breakpoint: 992,
                  settings: "unslick"
              },
          ],
      }).on('setPosition', function (event, slick) {
          slick.$slides.css('height', slick.$slideTrack.height() + 'px');
      });
  });
  */

  (0, _jquery2.default)('.slider-tipos:not(.slick-initialized)').each(function (index, element) {
    (0, _jquery2.default)(element).slick({
      autoplay: false,
      dots: true,
      arrows: false,
      infinite: false,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }, {
        breakpoint: 992,
        settings: "unslick"
      }]
    }).on('setPosition', function (event, slick) {
      slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });
  });
  (0, _jquery2.default)('.slider-beneficios:not(.slick-initialized)').each(function (index, element) {
    (0, _jquery2.default)(element).slick({
      autoplay: false,
      dots: true,
      arrows: true,
      infinite: false,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: "<button class='slick-prev slick-arrow' aria-label='Previous' type='button' style=''><div class='arrow'></div>Previous</button>",
      nextArrow: "<button class='slick-next slick-arrow' aria-label='Next' type='button' style=''><div class='arrow'></div>Next</button>",
      responsive: [{
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: true
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          dots: true
        }
      }]
    }).on('setPosition', function (event, slick) {
      slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });
  });
  if ((0, _jquery2.default)('.link-video').length > 0) (0, _jquery2.default)('.link-video').magnificPopup({
    type: 'iframe',
    midClick: true,
    mainClass: 'mfp-fade',
    removalDelay: 300,
    callbacks: {
      open: function open() {},
      change: function change() {},
      close: function close() {}
    }
  });

  _aos2.default.init({
    once: true
  });

  _gsap2.default.registerPlugin(_ScrollTrigger.ScrollTrigger);

  _gsap2.default.utils.toArray('.parallax-default').forEach(function (panel, index) {
    _gsap2.default.set(panel, {
      y: "+100"
    });

    _gsap2.default.to(panel, {
      y: 0,
      ease: "none",
      opacity: 1,
      scrollTrigger: {
        trigger: (0, _jquery2.default)(panel),
        scrub: true,
        end: "center center"
      }
    });
  });

  _gsap2.default.utils.toArray('.fadein-default').forEach(function (panel, index) {
    _gsap2.default.set(panel, {
      opacity: 0
    });

    _gsap2.default.to(panel, {
      ease: "none",
      opacity: 1
    });
  });

  (0, _jquery2.default)(window).resize(function () {
    (0, _jquery2.default)('.slick-initialized')[0].slick.refresh();
  });
  /*
  $(window).on("scroll", function () {
      AOS.init();
  });
  */
});